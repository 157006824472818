<template>
  <div>
    <v-card v-if="device" flat outlined>
      <v-card-title
        >Environment Variables
        <v-spacer></v-spacer>
        <v-tooltip left>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              icon
              v-on="tooltip"
              href="https://docs.synpse.net/synpse-core/devices/environment-variables"
              target="_blank"
              rel="noopener"
            >
              <v-icon color="grey" small>help</v-icon>
            </v-btn>
          </template>
          <span>
            All device environment variables will be available for applications
            running on this device
          </span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="deviceEnvironmentVariables"
          sort-by="key"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:top>
            <v-layout>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    class="primary--text mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Env Var
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <form v-on:submit.prevent="save()">
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.key"
                              label="Key"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.value"
                              label="Value"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-btn v-show="false" hidden type="submit"></v-btn>
                    </form>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn class="primary--text" text @click="close">
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="primary--text" text @click="save">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline"
                    >Are you sure you want to delete this environment
                    variable?</v-card-title
                  >
                  <v-card-actions>
                    <v-btn class="primary--text" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn class="primary--text" text @click="deleteItemConfirm"
                      >Delete</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-layout>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:no-data> No Environment Variables Defined </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>


<script>

export default {
  props: {
    device: Object
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Key',
          align: 'start',
          sortable: true,
          value: 'key',
        },
        { text: 'Value', value: 'value' },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        key: '',
        value: ''
      },
      defaultItem: {
        key: '',
        value: ''
      }
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  computed: {
    projectId() {
      return this.$props.device.projectId
    },
    deviceId() {
      return this.$props.device.id
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Env Variable' : 'Edit Env Variable'
    },
    deviceEnvironmentVariables() {
      let environmentVariables = []
      if (this.device && this.device.environmentVariables !== undefined) {
        for (const [key, value] of Object.entries(this.device.environmentVariables)) {
          environmentVariables.push({
            "key": key,
            "value": value,
          })
        }
      }

      return environmentVariables
    },
    loading() {
      return this.$store.state.device.loading
    },
    error() {
      return this.$store.state.device.error
    }
  },
  // mounted() {
  //   this.refresh()
  // },

  methods: {
    refresh() {
      let q = {
        projectId: this.projectId,
        deviceId: this.deviceId
      }
      this.$store.dispatch('GetDevice', q)
    },
    editItem(item) {
      this.editedIndex = this.deviceEnvironmentVariables.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.deviceEnvironmentVariables.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      let payload = {
        projectId: this.device.projectId,
        deviceId: this.device.id,
        key: this.editedItem.key,
      }
      await this.$store.dispatch('DeleteDeviceEnvironmentVariable', payload)

      let q = {
        projectId: this.device.projectId,
        deviceId: this.device.id,
      }
      await this.$store.dispatch('GetDevice', q)

      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      let payload = {
        projectId: this.device.projectId,
        deviceId: this.device.id,
        value: this.editedItem
      }
      await this.$store.dispatch('SetDeviceEnvironmentVariable', payload)
      let q = {
        projectId: this.device.projectId,
        deviceId: this.device.id,
      }
      await this.$store.dispatch('GetDevice', q)

      this.close()
    },
  }
}
</script>