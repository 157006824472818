var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.device)?_c('v-card',{attrs:{"flat":"","outlined":""}},[_c('v-card-title',[_vm._v("Environment Variables "),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","href":"https://docs.synpse.net/synpse-core/devices/environment-variables","target":"_blank","rel":"noopener"}},tooltip),[_c('v-icon',{attrs:{"color":"grey","small":""}},[_vm._v("help")])],1)]}}],null,false,3008903977)},[_c('span',[_vm._v(" All device environment variables will be available for applications running on this device ")])])],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.deviceEnvironmentVariables,"sort-by":"key","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-layout',[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary--text mb-2",attrs:{"outlined":""}},'v-btn',attrs,false),on),[_vm._v(" New Env Var ")])]}}],null,false,4294630520),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Key"},model:{value:(_vm.editedItem.key),callback:function ($$v) {_vm.$set(_vm.editedItem, "key", $$v)},expression:"editedItem.key"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Value"},model:{value:(_vm.editedItem.value),callback:function ($$v) {_vm.$set(_vm.editedItem, "value", $$v)},expression:"editedItem.value"}})],1)],1)],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"hidden":"","type":"submit"}})],1)]),_c('v-card-actions',[_c('v-btn',{staticClass:"primary--text",attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-spacer'),_c('v-btn',{staticClass:"primary--text",attrs:{"text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this environment variable?")]),_c('v-card-actions',[_c('v-btn',{staticClass:"primary--text",attrs:{"text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-spacer'),_c('v-btn',{staticClass:"primary--text",attrs:{"text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Delete")])],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}},{key:"no-data",fn:function(){return [_vm._v(" No Environment Variables Defined ")]},proxy:true}],null,false,2814666340)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }